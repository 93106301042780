<template>
  <div>
    <div :id="editorData.popupId">
      <template v-if="showsDataEditor">
        <div class="DynPopUp2-wrap DynPopUp2-zoom-in" :class="{ 'DynPopUp2-ready': isEditorReady }"
             :id="editorData.popupId">
          <div class="DynPopUp2-container">
            <div class="DynPopUp2-content">
              <div class="whitePopup zoom-anim-dialog" :class="columnClass">
                <component :is="componentType"
                           @editorIsReady="isEditorReady = true"
                           @columnClassComputed="setColumnClass"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <loading-sheet/>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { defineAsyncComponent } from 'vue';
  import LoadingSheet from '@/components/DataList/LoadingSheet';

  export default {
    name: 'DynPopup2',
    components: {
      LoadingSheet,
      'announcement-editor': defineAsyncComponent(() => import('../Announcements/Editor/AnnouncementEditor')),
      'data-editor': defineAsyncComponent(() => import('./DataEditor')),
      'visit-plan-editor': defineAsyncComponent(() => import('../VisitPlans/VisitPlanEditor')),
    },
    data() {
      return {
        isEditorReady: false,
        columnClass: '',
      };
    },
    computed: {
      componentType() {
        switch (this.editorData.dataType) {
          case 'Announcements': return 'announcement-editor';
          case 'VisitPlans': return 'visit-plan-editor';
          default: return 'data-editor';
        }
      },
      ...mapState('DataEditorStore', ['showsDataEditor', 'editorData']),
    },
    methods: {
      setColumnClass(columnClass) {
        this.columnClass = columnClass;
      },
    },
  };
</script>

<style scoped>

</style>
