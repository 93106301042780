<template>
  <div class="loadingDv" v-if="isLoading">
    <i class="fa fa-cog fa-spin"></i>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'LoadingSheet',
    computed: {
      ...mapState('DataEditorStore', ['isLoading']),
    },
  };
</script>

<style scoped>
  .loadingDv {
    position: fixed;
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    top: 50%;
    margin-top: -25px;
  }

  .loadingDv i {
    font-size: 50px;
    color: #bdc3c7;
  }
</style>
